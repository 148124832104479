import React from 'react';
import './StudentCourseCard.css';
import { FaRegClock, FaRegCircleCheck  } from 'react-icons/fa6';


interface StudentCourseCardProps {
  courseTitle: string;
  teacherName: string;
  submissionPercentage: number;
  inTimeSubmissionPercentage: number;
  statusClass: string;
  averageGradeName: string;
  onClick: () => void;
}

const StudentCourseCard: React.FC<StudentCourseCardProps> = ({
  courseTitle,
  teacherName,
  submissionPercentage,
  inTimeSubmissionPercentage,
  statusClass,
  averageGradeName,
  onClick,
}) => {
  return (
    <div className="student-course-card" onClick={onClick}>
      <div className="student-course-card-title">{courseTitle}</div>
      <div className="student-course-card-content">
        <div className="student-course-card-teacher-container">
          <div className="student-course-card-teacher">{averageGradeName}</div>
        </div>
        <div className="student-course-card-percentage-status-container">
          <div className="student-course-card-percentage-container">
            <FaRegCircleCheck className="student-course-card-icon" title="Odevzdáno" />
            <div className="student-course-card-percentage">
            { submissionPercentage.toFixed(1) } %

            </div>
          </div>
          <div className="student-course-card-percentage-container">
            <FaRegClock className="student-course-card-icon" title="Odevzdáno včas"/>
            <div className="student-course-card-percentage">
            { inTimeSubmissionPercentage.toFixed(1) } %
            </div>
          </div>
          <div className={`student-course-card-status ${statusClass}`} />

        </div>
      </div>
    </div>
  );
};

export default StudentCourseCard;