import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaAngleRight, FaRegClock, FaRegCircleCheck  } from 'react-icons/fa6';
import './StudentView.css';
import StudentCourseCard from './StudentCourseCard';
import StudentCourseworkTable from './StudentCourseworkTable';
import { getAverageGradeName, encodeStudentName, decodeStudentName, getIntegrationDisplayText, CourseWork, calculateAverageGrade, calculateSubmissionPercentage, calculateSubmissionPercentageInTime, determineStatusClass } from './utils';

interface CourseTableProps {
  data: CourseWork[];
}

const StudentView: React.FC<CourseTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [courses, setCourses] = useState<{ [key: string]: CourseWork[] }>({});
  const [studentContext, setStudentContext] = useState<string | null>(null);

  // Parse URL to get the current selected course ID and student context
  const searchParams = new URLSearchParams(location.search);
  const selectedCourseId = searchParams.get('courseId');
  const encodedStudentName = searchParams.get('student');

  useEffect(() => {
    if (encodedStudentName) {
      const decodedName = decodeStudentName(encodedStudentName);
      setStudentContext(decodedName); // Set student context if available in URL
    }
  }, [encodedStudentName]);

  // Aggregate data by courseId
  useEffect(() => {
    const aggregatedCourses = data.reduce<{ [key: string]: CourseWork[] }>((acc, curr) => {
      if (!acc[curr.courseId]) {
        acc[curr.courseId] = [];
      }
      acc[curr.courseId].push(curr);
      return acc;
    }, {});

    // Sort courses by deadline
    Object.keys(aggregatedCourses).forEach(courseId => {
      aggregatedCourses[courseId].sort((a, b) => new Date(b.deadline).getTime() - new Date(a.deadline).getTime());
    });

    setCourses(aggregatedCourses);
  }, [data]);

  // Sort course IDs alphabetically
  const sortedCourseIds = Object.keys(courses).sort((a, b) =>
    courses[a][0].courseTitle.localeCompare(courses[b][0].courseTitle)
  );

  const handleCardClick = (courseId: string) => {
    const newUrl = studentContext ? `?student=${encodeStudentName(studentContext)}&courseId=${courseId}` : `?courseId=${courseId}`;
    navigate(newUrl);
  };

  const handleBreadcrumbClick = () => {
    const newUrl = studentContext ? `?student=${encodeStudentName(studentContext)}` : '';
    navigate(newUrl);
  };

  // Ensure selected course exists before rendering
  const selectedCourse = selectedCourseId ? courses[selectedCourseId] : null;

  const groupIntegratedCourses = (courseIds: string[], courses: { [key: string]: CourseWork[] }) => {
    const groupedCourses: { [key: string]: string[] } = {};
    const ungroupedCourses: string[] = [];
  
    courseIds.forEach((courseId) => {
      const integrationFlag = courses[courseId][0].integration; // Assumes integration field is on the first item
      if (integrationFlag) {
        if (!groupedCourses[integrationFlag]) {
          groupedCourses[integrationFlag] = [];
        }
        groupedCourses[integrationFlag].push(courseId);
      } else {
        ungroupedCourses.push(courseId);
      }
    });
  
    // Move groups with only one course to ungroupedCourses
    Object.keys(groupedCourses).forEach((integrationFlag) => {
      if (groupedCourses[integrationFlag].length === 1) {
        ungroupedCourses.push(groupedCourses[integrationFlag][0]);
        delete groupedCourses[integrationFlag];
      }
    });
  
    return { groupedCourses, ungroupedCourses };
  };
  
  const { groupedCourses, ungroupedCourses } = groupIntegratedCourses(sortedCourseIds, courses);

  
  return (
    <div className="student-view-container">
      <div className="breadcrumb-container">
        <div
          className={`breadcrumb-item ${!selectedCourseId ? 'active' : ''}`}
          onClick={handleBreadcrumbClick}
        >
          Courses
        </div>
        {selectedCourseId && selectedCourse && (
          <>
            <span className="breadcrumb-separator">
              <FaAngleRight />
            </span>
            <div className="breadcrumb-item active">
              {selectedCourse[0].courseTitle}
            </div>
          </>
        )}
      </div>

      {selectedCourseId && selectedCourse ? (
        <StudentCourseworkTable
          courseTitle={selectedCourse[0].courseTitle}
          courseWorks={selectedCourse}
        />
      ) : (
        <div className="course-cards-container">
          {/* Render grouped courses first */}
          {Object.keys(groupedCourses).map((integrationFlag) => {
            const courseIds = groupedCourses[integrationFlag];
            const combinedCourseWorks = courseIds.flatMap(courseId => courses[courseId]);
            const combinedSubmissionPercentage = calculateSubmissionPercentage(combinedCourseWorks);
            const combinedInTimeSubmissionPercentage = calculateSubmissionPercentageInTime(combinedCourseWorks);
            const combinedAverageQuality = calculateAverageGrade(combinedCourseWorks);
  
            return (
              <div key={integrationFlag} className="integrated-course-group rounded-border">
                <div className="integrated-course-header">
                  <h3 className="integration-title">{getIntegrationDisplayText(integrationFlag)}</h3>
                  <div className="integration-summary-container">
                    <div className="student-course-card-percentage-container">
                      <FaRegCircleCheck className="student-course-card-icon" title="Odevzdáno" />
                      <div className="student-course-card-percentage">
                        {combinedSubmissionPercentage.toFixed(1)}%
                      </div>
                    </div>
                    <div className="student-course-card-percentage-container">
                      <FaRegClock className="student-course-card-icon" title="Odevzdáno včas" />
                      <div className="student-course-card-percentage">
                        {combinedInTimeSubmissionPercentage.toFixed(1)}%
                      </div>
                    </div>
                    <div className={`student-course-card-status ${determineStatusClass(combinedSubmissionPercentage, combinedAverageQuality)}`} />
                  </div>
                </div>

                {/* Render individual course cards within the group */}
                {courseIds.map((courseId) => {
                  const courseWorks = courses[courseId];
                  const submissionPercentage = calculateSubmissionPercentage(courseWorks);
                  const averageGrade = calculateAverageGrade(courseWorks);
                  const statusClass = determineStatusClass(submissionPercentage, averageGrade);
                  const inTimeSubmissionPercentage = calculateSubmissionPercentageInTime(courseWorks);
                  const averageGradeName = getAverageGradeName(courseWorks);

                  return (
                    <StudentCourseCard
                      key={courseId}
                      courseTitle={courseWorks[0].courseTitle}
                      teacherName={courseWorks[0].teacherName!}
                      submissionPercentage={submissionPercentage}
                      inTimeSubmissionPercentage={inTimeSubmissionPercentage}
                      statusClass={statusClass}
                      averageGradeName={averageGradeName}
                      onClick={() => handleCardClick(courseId)}
                    />
                  );
                })}
              </div>

            );
          })}
      
          {/* Render ungrouped courses */}
          {ungroupedCourses.map((courseId) => {
            const courseWorks = courses[courseId];
            const submissionPercentage = calculateSubmissionPercentage(courseWorks);
            const averageGrade = calculateAverageGrade(courseWorks);
            const statusClass = determineStatusClass(submissionPercentage, averageGrade);
            const inTimeSubmissionPercentage = calculateSubmissionPercentageInTime(courseWorks);
            const averageGradeName = getAverageGradeName(courseWorks);
      
            return (
              <StudentCourseCard
                key={courseId}
                courseTitle={courseWorks[0].courseTitle}
                teacherName={courseWorks[0].teacherName!}
                submissionPercentage={submissionPercentage}
                inTimeSubmissionPercentage={inTimeSubmissionPercentage}
                statusClass={statusClass}
                averageGradeName={averageGradeName}
                onClick={() => handleCardClick(courseId)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StudentView;
